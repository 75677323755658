// extracted by mini-css-extract-plugin
export var menu = "menu-module--menu--2dmza";
export var menuItem = "menu-module--menuItem--8dJRq";
export var menuLink = "menu-module--menuLink--2qJbN";
export var mobileMenuWrap = "menu-module--mobileMenuWrap--1uHig";
export var open = "menu-module--open--1XYQX";
export var buttonOpenMenu = "menu-module--buttonOpenMenu--UDbgu";
export var burgerIcon = "menu-module--burgerIcon--187ow";
export var buttonCloseMenu = "menu-module--buttonCloseMenu--1K7Iw";
export var orderServiceMobile = "menu-module--orderServiceMobile--OtuZU";
export var toContactMobile = "menu-module--toContactMobile--M6H6q";