// extracted by mini-css-extract-plugin
export var serviceModalContent = "serviceModal-module--serviceModalContent--Cps0k";
export var serviceModalContentWrap = "serviceModal-module--serviceModalContentWrap--8flHC";
export var title = "serviceModal-module--title--2lUrf";
export var inputsWrap = "serviceModal-module--inputsWrap--1PW1A";
export var typesOfService = "serviceModal-module--typesOfService--6uQlx";
export var checkboxWrap = "serviceModal-module--checkboxWrap--3hcYh";
export var checkbox = "serviceModal-module--checkbox--2qR4y";
export var inputWrap = "serviceModal-module--inputWrap--2ZAux";
export var svgIcon = "serviceModal-module--svgIcon--34M3c";
export var input = "serviceModal-module--input--1Q0c0";
export var activeInput = "serviceModal-module--activeInput--F7bpI";
export var label = "serviceModal-module--label--1voUA";
export var isRequired = "serviceModal-module--isRequired--1DDoj";
export var textarea = "serviceModal-module--textarea--22bDM";
export var name = "serviceModal-module--name--oBEbc";
export var surname = "serviceModal-module--surname--exUlv";
export var email = "serviceModal-module--email--3PKw5";
export var tel = "serviceModal-module--tel--3wLwX";
export var typeOfPlace = "serviceModal-module--typeOfPlace--muA1I";
export var widthOfPlace = "serviceModal-module--widthOfPlace--Sps3u";
export var descriptionOfProblem = "serviceModal-module--descriptionOfProblem--3pAih";
export var submitButton = "serviceModal-module--submitButton--1qiUs";